@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.cdnfonts.com/css/lapsus-pro');

html {
  font-family: 'Lapsus Pro', sans-serif;
}

.heartbeat {
	animation: beat 0.7s infinite alternate;
}

@keyframes beat {
	to { transform: scale(1.1); }
}

@keyframes slideInBounce {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  60% {
    transform: translateY(10%);
    opacity: 1;
  }
  80% {
    transform: translateY(-5%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideOutBounce {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  20% {
    transform: translateY(-5%);
  }
  40% {
    transform: translateY(10%);
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
}

.navbar-slide-in {
  animation: slideInBounce 0.7s ease-out forwards;
}

.navbar-slide-out {
  animation: slideOutBounce 0.7s ease-in forwards;
}

.text-custom-brown {
  color: #301d09;
}

.slick-slide div {
  outline: none;
}

.slick-slide a {
  outline: none;
}
